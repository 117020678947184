<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div v-show="pagetype === 1">
        <!-- 条件搜索 -->
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="商品名称">
                  <a-input v-model="queryParam.postCode" placeholder="请输入" allow-clear />
                </a-form-item>
              </a-col>
              <template v-if="advanced">
                <a-col :md="8" :sm="24">
                  <a-form-item label="上架状态">
                    <a-select placeholder="请选择" v-model="queryParam.status" style="width: 100%" allow-clear>
                      <a-select-option
                        v-for="(d, index) in dict.type['sys_normal_disable']"
                        :key="index"
                        :value="d.value">{{ d.label }}</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </template>
              <a-col :md="!advanced && 8 || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
                  <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                  <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                  <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? '收起' : '展开' }}
                    <a-icon :type="advanced ? 'up' : 'down'" />
                  </a>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <!-- 操作 -->
        <div class="table-operations">
          <a-button type="primary" @click="handleAdd" v-hasPermi="['mall:goods:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button type="primary" :disabled="single" @click="handleUpdate" v-hasPermi="['mall:goods:edit']">
            <a-icon type="edit" />修改
          </a-button>
          <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['mall:goods:remove']">
            <a-icon type="delete" />删除
          </a-button>
          <table-setting
            :style="{ float: 'right' }"
            :table-size.sync="tableSize"
            v-model="columns"
            :refresh-loading="loading"
            @refresh="getList" />
        </div>

        <!-- 数据展示 -->
        <a-table
          :loading="loading"
          :size="tableSize"
          rowKey="postId"
          :columns="columns"
          :data-source="list"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"
          :bordered="tableBordered">
          <span slot="image" slot-scope="text, record">
            <a-avatar :src="text" shape="square" size="large">
            </a-avatar>
          </span>
          <!-- <span slot="status" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_normal_disable']" :value="record.status" />
        </span> -->
          <span slot="pssnums" slot-scope="text, record">
            <div>价格：{{ record.price }}</div>
            <div>销量：{{ record.sales }}</div>
            <div>库存：{{ record.stock }}</div>
          </span>
          <span slot="status" slot-scope="text, record">
            <dict-tag :options="dict.type['goods_onsale']" :value="record.status" />
            <a-popconfirm
              ok-text="是"
              cancel-text="否"
              @confirm="confirmHandleStatus(record)"
              @cancel="cancelHandleStatus(record)">
              <span slot="title">确认<b>{{ record.status === '1' ? '下架' : '上架' }}</b>吗?</span>
              <a-switch checked-children="上架" un-checked-children="下架" :checked="record.status == 1" />
            </a-popconfirm>
          </span>
          <span slot="operation" slot-scope="text, record">
            <a @click="handleUpdate(record, undefined)" v-hasPermi="['system:article:edit']">
              <a-icon type="edit" />修改
            </a>
            <a-divider type="vertical" v-hasPermi="['system:article:remove']" />
            <a @click="handleDelete(record)" v-hasPermi="['system:article:remove']">
              <a-icon type="delete" />删除
            </a>
          </span>
        </a-table>
        <!-- 分页 -->
        <a-pagination
          class="ant-table-pagination"
          show-size-changer
          show-quick-jumper
          :current="queryParam.pageNum"
          :total="total"
          :page-size="queryParam.pageSize"
          :showTotal="total => `共 ${total} 条`"
          @showSizeChange="onShowSizeChange"
          @change="changeSize" />
      </div>
      <!-- 增加修改 -->
      <!-- <create-pform v-show="pagetype === 2" ref="CreatePform" :statusOptions="dict.type['sys_normal_disable']"
        @ok="getList" /> -->
    </a-card>
  </page-header-wrapper>
</template>

<script>
import CreatePform from './modules/CreatePform'
// import { tableMixin } from '@/store/table-mixin'
import * as goods from '@/api/mall/goods'
import { list } from '@/api/mall/goodscate'

export default {
  name: 'Post',
  components: {
    CreatePform
  },
  // mixins: [tableMixin],
  dicts: ['sys_normal_disable'],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: true,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      pagetype: 1, // 1商品列表 2新增编辑商品页面
      tableSize: 'small',
      tableBordered: false,
      total: 0,
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        postCode: undefined,
        postName: undefined,
        status: undefined
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'center',
          width: 50
        },
        {
          title: '商品图片',
          dataIndex: 'image',
          ellipsis: true,
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'image' }
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'left',
          width: 300,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '商品类型',
          dataIndex: 'product_type',
          ellipsis: true,
          align: 'center',
          width: 80
        },
        // {
        //   title: '价格',
        //   dataIndex: 'price',
        //   align: 'center',
        //   width: 80,
        //   colSpan: 0
        // },
        // {
        //   title: '销量',
        //   dataIndex: 'sales',
        //   align: 'center',
        //   width: 50,
        //   colSpan: 0
        // },
        // {
        //   title: '库存',
        //   dataIndex: 'stock',
        //   align: 'center',
        //   width: 50,
        //   colSpan: 0
        // },
        {
          title: '价格/销量/库存',
          dataIndex: 'pssnums',
          align: 'left',
          width: 120,
          scopedSlots: { customRender: 'pssnums' }
          // colSpan: 3

          // customRender: (value, row, index) => {
          //   const obj = {
          //     children: value,
          //     attrs: {},
          //   };
          //   if (index === 4) {
          //     obj.attrs.rowSpan = 3;
          //   }
          //   // These two are merged into above cell
          //   if (index === 5) {
          //     obj.attrs.rowSpan = 0;
          //   }
          //   if (index === 6) {
          //     obj.attrs.colSpan = 0;
          //   }
          //   return obj;
          // }
        },
        {
          title: '规格',
          dataIndex: 'spec_type',
          scopedSlots: { customRender: 'spec_type' },
          align: 'center',
          width: 80
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created() {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询商品列表 */
    getList() {
      const response = {
        'code': '20001',
        'success': true,
        'message': '成功.',
        'data': {
          'records': [
            {
              'id': 88,
              'mer_id': 0,
              'image': 'https://module.niurenjianzhan.com/img/logo.05eadbd7.png',
              'recommend_image': '',
              'slider_image': [
                'https://module.niurenjianzhan.com/img/logo.05eadbd7.png',
                'https://module.niurenjianzhan.com/img/logo.05eadbd7.png'
              ],
              'name': '条香氛无火香薰精油卧室助眠除臭 纯洁100ml',
              'store_info': '',
              'keyword': '藤条香氛无火香薰精油卧室助眠',
              'bar_code': '',
              'cate_id': '54,22',
              'price': '52.00',
              'vip_price': '0.00',
              'ot_price': '58.00',
              'postage': '0.00',
              'unit_name': '瓶',
              'sort': 1015,
              'sales': 54,
              'stock': '122',
              'is_show': 1,
              'is_hot': 0,
              'is_benefit': 0,
              'is_best': 0,
              'is_new': 0,
              'is_virtual': 0,
              'virtual_type': 0,
              'add_time': 1636775979,
              'is_postage': 0,
              'is_del': 0,
              'mer_use': 0,
              'give_integral': '100.00',
              'cost': '60.00',
              'is_seckill': 0,
              'is_bargain': null,
              'is_good': 0,
              'is_sub': 0,
              'is_vip': 0,
              'ficti': 150,
              'browse': 0,
              'code_path': '',
              'soure_link': '',
              'temp_id': 0,
              'spec_type': 1,
              'activity': '0,3,2,1',
              'spu': '9810156456732',
              'label_id': '11,15,18',
              'video_link': 'http://60.190.250.75:8082/fileserver/-5001/6/3/36/60336.mp4',
              'command_word': '',
              'recommend_list': '89,92,94,109',
              'vip_product': 0,
              'presale': 0,
              'presale_start_time': 0,
              'presale_end_time': 0,
              'presale_day': 0,
              'logistics': '2',
              'freight': 2,
              'custom_form': '[]',
              'is_limit': 1,
              'limit_type': 1,
              'limit_num': 1,
              'collect': 48,
              'likes': 0,
              'visitor': 1622,
              'cate_name': '家具软装/居家家具,官方商城/新品上市',
              'stock_attr': true,
              'product_type': '普通商品'
            }, {
              'id': 93,
              'mer_id': 0,
              'image': 'https://module.niurenjianzhan.com/img/logo.05eadbd7.png',
              'recommend_image': '',
              'slider_image': [
                'https://module.niurenjianzhan.com/img/logo.05eadbd7.png',
                'https://module.niurenjianzhan.com/img/logo.05eadbd7.png'
              ],
              'name': '【LESHUCANGHUA】创意插画动漫人物月野兔SailorMoon水兵月明信片',
              'store_info': '',
              'keyword': '',
              'bar_code': '',
              'cate_id': '82',
              'price': '300.00',
              'vip_price': '0.00',
              'ot_price': '354.00',
              'postage': '0.00',
              'unit_name': '件',
              'sort': 890,
              'sales': 45,
              'stock': '12',
              'is_show': 1,
              'is_hot': 0,
              'is_benefit': 1,
              'is_best': 0,
              'is_new': 0,
              'is_virtual': 0,
              'virtual_type': 0,
              'add_time': 1636789299,
              'is_postage': 0,
              'is_del': 0,
              'mer_use': 0,
              'give_integral': '100.00',
              'cost': '244.00',
              'is_seckill': 0,
              'is_bargain': null,
              'is_good': 0,
              'is_sub': 1,
              'is_vip': 0,
              'ficti': 0,
              'browse': 0,
              'code_path': '',
              'soure_link': '',
              'temp_id': 69,
              'spec_type': 1,
              'activity': '0,1,2,3',
              'spu': '5199569781285',
              'label_id': '7',
              'video_link': '',
              'command_word': '',
              'recommend_list': '',
              'vip_product': 0,
              'presale': 0,
              'presale_start_time': 0,
              'presale_end_time': 0,
              'presale_day': 0,
              'logistics': '1',
              'freight': 3,
              'custom_form': '[]',
              'is_limit': 0,
              'limit_type': 0,
              'limit_num': 0,
              'collect': 35,
              'likes': 0,
              'visitor': 1240,
              'cate_name': '官方商城/vans',
              'stock_attr': true,
              'product_type': '普通商品'
            }
          ],
          'total': 8,
          'size': 0,
          'current': 1,
          'orders': [],
          'optimizeCountSql': true,
          'searchCount': true,
          'countId': null,
          'maxLimit': null,
          'pages': 0
        },
        'errors': null,
        'time': '2023-06-02 08:44:03'
      }
      this.list = response.data.records
      this.total = response.data.total
      return
      // TODO
      this.loading = true
      goods.list(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        postCode: undefined,
        postName: undefined,
        status: undefined
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.postId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({
        path: '/mall/goodsForm',
        params:
        {
          id: undefined,
          formTitle: '添加商品'
        }
      })
    },
    /** 编辑商品信息 **/
    handleUpdate(row) {
      const id = row ? row.id : ids
      this.$router.push({
        path: '/mall/goodsForm',
        params:
        {
          id: id,
          formTitle: '修改商品'
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const id = row.id || this.ids
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk() {
          return goods.del(id).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() { }
      })
    },
    confirmHandleStatus(info) {
      console.log('info', info)
    },
    cancelHandleStatus() {

    }
  }
}
</script>
